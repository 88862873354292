import {Toast} from "vant";

export function token(){
    let token = "";
    try {
        let code = localStorage.getItem("token");
        if (code){
            token = code;
        }
        return token;
    }catch (e) {
        return token;
    }
}

export function toSubscribe(id){
    let pay = "prepay_id="+id.package.prepay_id;
    window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        'appId': id.appId,//"wx5ef0e4fab3c1525d",
        'timeStamp': id.timeStamp.toString(),
        'nonceStr': id.nonceStr,
        'package': pay,
        'signType': id.signType,
        'paySign': id.paySign,
        function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            }
        }
    })
    setTimeout(() => {
        window.location.href = "https://wx.sixu.work/jxfw"
    },5000)
}