<template>
  <div>
    <van-row v-if="bindinfo.phoneNum!=null" class="mt20">
      <van-col span="22" offset="1">
        <van-row>
          <van-col span="24">
            <van-col span="4" offset="1">
              <van-image width="3rem" height="3rem" round :src="bindinfo.headimgurl"></van-image>
            </van-col>
            <van-col span="18" style="line-height: 3rem;" v-if="bindinfo.nickname!=null"><span class="txt">{{bindinfo.nickname}}</span></van-col>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="24" class="mt20">
            <van-cell-group>
              <van-field v-model="bindinfo.phoneNum" label="绑定手机号" readonly/>
              <van-field v-model="bindinfo.relaTime" label="绑定时间" readonly/>
            </van-cell-group>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-row v-if="bindinfo==1">
      <van-col span="22" offset="1" class="mt20">
        <van-row>
          <van-col span="22" offset="1" class="mt20 mcenter">
            <van-image width="3rem" height="3rem" round :src="img"></van-image>
          </van-col>
        </van-row>
        <van-row v-if="this.hidebut==1">
          <van-col span="24" class="mt20 mcenter">
            <van-icon name="checked" color="#1989fa" size="5rem"/>
            <h4 style="margin-top: 15px;">提交成功</h4>
          </van-col>
        </van-row>
        <van-row v-else>
          <van-col class="mt20">
            <van-form @submit="onSubmit">
              <van-field v-model="phone" name="phone" label="手机号码" placeholder="手机号码" maxlength="11" required :rules="[{ required: true, message: '必填' }]"/>
              <van-field v-model="imgverify" name="imgverify" label="图片验证码" placeholder="图片验证码" required :rules="[{ required: true, message: '必填' }]">
                <van-image slot="button" width="80" height="30" :src="yzmimg" fit="fill"></van-image>
              </van-field>
              <van-field v-model="verifycode" name="verifycode" label="短信验证码" placeholder="短信验证码" required>
                <van-button slot="button" size="small" type="primary" @click="gainSMSCode">{{time}}</van-button>
              </van-field>
              <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">提交</van-button>
              </div>
            </van-form>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import mixins from '@/mixins'
import qs from "qs";
import {Toast} from "vant";
import {gainBindPhoneNum, postBindInfo, SMSverification} from "@/api/api";
export default {
  name: "index",
  mixins:[mixins],
  data(){
    return{
      img:require('@/assets/sixu.png'),
      phone: '',//手机号码
      imgverify:'',//图片验证码
      verifycode: '',//短信验证码
      //userinfo:{},//用户信息
      time:"获取验证码",
      isClick:true,
      yzmimg:'',
      code:"",//用户授权的code
      bindinfo:{},//用户绑定信息
      interval:"",
      hidebut:0,
    }
  },
  created() {
    this.getCode();
  },
  mounted() {
    this.initData();
    this.getBindInfo();
    this.gainImgCode();
  },
  methods:{
    getCode(){
      /*let href = "https://sixu.work/binding?code=0215mrll2y2Q384NSPll2yyy9q05mrlB&state=70aec52f26e3451c89e609ff6c80307c"
      if (href.indexOf("state") != -1){
        this.code= qs.parse(href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(href.split("#")[0].split("?")[1]).state;
        console.log("code",this.code);
        console.log("state",this.state);
      }*/
      if (window.location.href.indexOf("state") != -1){
        this.code = qs.parse(window.location.href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(window.location.href.split("#")[0].split("?")[1]).state
      }
      //this.code = "0d3qyN000Gz0KP19q4100qhFrY3qyN02";
    },
    getBindInfo(){
      if (this.code){
        gainBindPhoneNum(this.code).then(res=>{
          //console.log("bind",res)
          if (res!=null&&res.data!=null&&res.data.content!=null) {
            if (res.data.content!=''){
              this.bindinfo = res.data.content[0];
            }else {
              //this.gainImgCode();
              this.bindinfo=1;
            }
          }else {
            this.bindinfo=1;
          }
        })
      }
      //this.bindinfo = 1;
    },
    //获取图片验证码
    gainImgCode(){
      if (this.code){
        this.yzmimg = this.BASE_URL + '/sso/auth/bindingcheck/' + this.code;
      }
    },
    gainSMSCode(){
      if (this.code){
        if (this.isClick){
          let data = {
            tel:"",
            code:this.code,
            checkcode:""
          };
          //验证手机号码是否正确
          let reg = /^[1][3,4,5,7,8][0-9]{9}$/;
          if (this.phone){
            if (!reg.test(this.phone)){
              Toast("您输入的电话号码有误")
            }else {
              data.tel = this.phone;
              if (this.imgverify!=''&&this.imgverify!=null){
                this.isClick=false;

                data.checkcode = this.imgverify;
                //console.log("data",data)
                //通过接口传数据并获取短信验证码
                SMSverification(data).then(res=>{
                  //console.log("res",res);
                  if (res!=null&&res.data!=null&&res.data.content!=null){
                    Toast.success(res.data.content);
                    let s = 60;
                    this.time = s + 's后重新获取';
                    this.interval = setInterval(()=>{
                      s--;
                      this.time = s + 's后重新获取';
                      if (s < 0) {
                        this.time = '重新获取';
                        this.isClick = true;
                        clearInterval(this.interval)
                      }
                    },1000)
                  }else {
                    if (res.data.errorCode=="002"){
                      Toast.fail(res.data.errorMsg)
                    }
                  }
                }).catch(err=>{
                  console.log(err)
                })
              }else {
                Toast("请输入图片验证码")
              }
            }
          }else {
            Toast("请输入电话号码")
          }
        }
      }
    },
    //通过接口将手机号，短信验证码，code传给后台
    onSubmit() {
      if (this.verifycode){
        let datas = {
          code:this.code,
          checkCode:this.verifycode,
          tel:this.phone,
        };
        //console.log('submit', this.phone);
        postBindInfo(datas).then(res=>{
          //console.log(res);
          if (res!=null&&res.data!=null&&res.data.content!=null){
            Toast.success("提交成功");
            //this.getBindInfo();
            clearInterval(this.interval);
            this.time = "已获取"
            this.isClick = false;
            this.hidebut = 1;
            //window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5ef0e4fab3c1525d&redirect_uri=https%3A%2F%2Fsixu.work%2Fbinding&response_type=code&scope=snsapi_userinfo&state=binding#wechat_redirect";
          }else {
            Toast.fail(res.data.errorMsg);
          }
        })
      }else {
        Toast("请输入短信验证码")
      }
    },
    initData(){
      this.bindinfo={};
    }
  },
}
</script>

<style scoped>
.mt3{margin-top: 3vh;}
.mt4{margin-top: 4vh;}
.mt5{margin-top: 5vh;}
.mt20{margin-top: 20px;}
.mb20{margin-bottom: 2vh;}
.mt15{margin-top: 15px;}
.mb10{margin-bottom: 1vh;}
.txt{vertical-align: middle;}
.mcenter{text-align: center;}
</style>